@font-face {
  font-family: "Core Sans C";
  src: local("Core Sans C"), url("./fonts/38E4F6_0_0.eot");
  src: local("Core Sans C"), url("./fonts/38E4F6_0_0.woff") format("woff");
}

.template {
  background-color: #C6CFD7;
  text-align: center;
  height: 1493px;
  width: 1056px;
  position: relative;
  box-shadow: 0 2px 8px rgba(31,45,61,.05);
  transform-origin: 0 0 0;
  margin-bottom: 10%;
}

.template * {
  font-family: "Core Sans C", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.white-layer {
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  width: 80%;
  height: 76%;
}

.content {
  position: relative;
  top: -160px;
}

.heroes-masthead {
  width: 80%;
  height: auto;
}

.mt-00 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.green {
  color: #445A63!important;
}

.main-header {
  color: #445A63;
  transform: scaleY(0.9);
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 1;
}

._border {
  border: 1px solid #000000;
  height: 0;
}

.name-field{
  width: 600px
}

.field {
  width: 200px
}

.weight-600 {
  font-weight: 600;
}

.secondary-header {
  display: block;
  color: #000000;
  font-size: 1.9rem;
  letter-spacing: 4px;
  transform: scaleY(0.9);
}

.recipient {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  color: #000000; 
  margin-top: 20px; 
  font-size: 2.3rem;
  font-weight: 100;
  width: 580px;
  min-height: 170px;
  max-height: 280px;
  position: relative;
  overflow-y: auto;
}

.name-wrap {
  display: flex;
  -webkit-box-align: center!important;
  -ms-flex-align: center!important;
  align-items: center!important;
  height: 100%;
  min-height: 170px;
  justify-content: center!important;
}

.message {
  color: #000000;
  margin-top: 15px;
  font-size: 1.3rem;
  font-weight: 400;
}

.issuer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
}

.title {
  color: #000000;
  font-size: 1.2rem;
  font-weight: 400;
}

.data {
  color: #000000;
  margin-top: 0px;
  font-size: 1.2rem;
  font-weight: bold;
  height: 70px;
  position: relative;
  text-align: center;
}
.data * {
  display: block;
  width: 100%;
  position: absolute;
  bottom:0;
}

.orange {
  color: #FF7531;
  font-size: 2.5rem;
  margin-top: 30px;
  font-weight: bold;
  font-style: italic;
  text-align: center;
}

.badge {
  position: relative;  
  float: right;
}

.badge img {
  width: 140px;
  height: auto;
  position: absolute;
  right: 50px;
  top: -70px;
}

.by {
  color: #000000;
  display: block;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 100px;
}