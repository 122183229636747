.full { 
  position: fixed; 
  top:0; 
  left: 0; 
  right: 0; 
  bottom: 0;
  /* height: 1800px;
  margin-bottom: 10%; */
}

.external-template {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 8px rgba(31,45,61,.05);
  border: 0;
}

